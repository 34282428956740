/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "earwax-friend-or-foe",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#earwax-friend-or-foe",
    "aria-label": "earwax friend or foe permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Earwax: Friend or Foe?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Earwax. Ew, right? Probably not anyone’s favorite topic of conversation. Well, you may be surprised that earwax is an integral part of the ear that should be celebrated!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Earwax, in normal amounts, is healthy — and very important. With antibacterial, protective, and lubricating properties, earwax coats the skin of the ear canal and repels water, dirt, bacteria, and other debris so it doesn’t reach the eardrum. It’s even a natural insect repellent! Without it, you could experience infections and injuries, and your ears would be very dry and itchy. So as you can see, earwax is most definitely a friend!"), "\n", React.createElement(ButtonCta, {
    copy: "Begin your no risk trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cleaning-your-ears-and-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cleaning-your-ears-and-hearing-aids",
    "aria-label": "cleaning your ears and hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cleaning your ears and hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, while earwax may seem more like a foe when you’re wearing hearing aids, it’s there to do an important job. Here are some simple do’s and don’ts to help you manage it to where it becomes second nature:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "DO…")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Use a simple washcloth to clean the external part of your ears (and dry them well) before you put on your hearing aids every day."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Gently wipe off all parts of your hearing aids with a soft cloth every evening after you take them off."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ensure the optimal performance of your hearing aids by checking often for earwax buildup."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Have your ears and hearing aids cleaned 2-3 times a year by a professional to ensure the best performance of your ears and your hearing aids. They can safely remove wax buildup with special tools."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "DON’T…")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ever stick anything into your ears to clean them. No Q-tips, pencils, folded napkin corners — nothing. Doing so may push earwax further down into your ear canal, which may cause a blockage and infection, and affect your hearing and hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Put your hearing aids in if your ears are damp or wet."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Delay going to the doctor if you’re having pain, ongoing itching, odor, drainage, or worsening of your hearing or performance of your hearing aids."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "IF…")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have problems with buildup or experience performance issues with your hearing aids:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Remove and change the earwax guards and filters."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Use the cleaning kit and tools that came with your hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clean around the sound outlet by twisting the brush to clear away any wax"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clear the ventilation opening by inserting and twisting the brush"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Brush off microphone openings"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Replace the brush with a new one if needed"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you experience discomfort, irritation, or wax buildup in your ears:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "From time to time consider using an over the counter ear lubricant according to package instructions daily for a few days to help soften earwax."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Try an over-the-counter earwax removal kit to help soften and break up earwax in your ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If your symptoms don’t improve after a few days, see your doctor."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Always remember, clean ears and clean hearing aids will help to ensure your devices keep functioning at optimal levels. Questions? Give us a shout, we’re always here to help!"), "\n", React.createElement(ButtonCta, {
    copy: "Begin your no risk trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
