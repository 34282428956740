import GATSBY_COMPILED_MDX from "/home/jenkins/workspace/e-GMBH_martech.ca-website_master/src/content/resources-post/earwax-friend-or-foe.md";
import React from 'react';
import {graphql} from 'gatsby';
import {Breadcrumb} from 'gatsby-plugin-breadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import AuthorBox from '../components/resources/AuthorBox';
import ArticleCard from '../components/resources/ArticleCard';
import cleanCrumbLabels from '../utils/cleanCrumbLabels';
import {crumbSeparator} from '../utils/variables';
const ResourcePostTemplate = ({pageContext, location, data: {mdx, allMdx, authorJson}, children}) => {
  const {breadcrumb: {crumbs}} = pageContext;
  return React.createElement(Layout, {
    fontSerif: true
  }, React.createElement(Seo, {
    article: true,
    title: mdx.frontmatter.metaTitle,
    description: mdx.frontmatter.metaDescription,
    image: mdx.frontmatter.image
  }), React.createElement("div", {
    className: "o-wrapper o-wrapper--small"
  }, React.createElement("div", {
    className: "o-row-2p5",
    style: {
      paddingTop: '40px'
    }
  }, React.createElement(Breadcrumb, {
    location: location,
    crumbSeparator: crumbSeparator,
    crumbs: cleanCrumbLabels(crumbs)
  })), React.createElement("div", {
    className: "o-row-4"
  }, React.createElement("div", {
    className: "c-resource-post"
  }, children)), React.createElement("div", {
    className: "o-row-4"
  }, React.createElement(AuthorBox, {
    name: authorJson.name,
    bio: authorJson.bio,
    image: authorJson.image
  })), allMdx.nodes.length > 0 && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "o-row-2"
  }, React.createElement("p", {
    style: {
      fontSize: '18px'
    }
  }, "Related Articles")), React.createElement("div", {
    className: "o-row-4"
  }, React.createElement("div", {
    className: "c-resource-cards-wrapper"
  }, allMdx.nodes.map((post, i) => React.createElement(ArticleCard, {
    key: i,
    url: `/resources/${post.frontmatter.category}/${post.frontmatter.slug}`,
    title: post.frontmatter.articleCardTitle,
    description: post.frontmatter.articleCardDescription
  })))))));
};
ResourcePostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ResourcePostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
	query ($slug: String!, $author: String!, $category: String!) {
		mdx(frontmatter: { slug: { eq: $slug } }) {
			frontmatter {
				metaTitle
				metaDescription
				image
			}
		}
		authorJson(name: { eq: $author }) {
			bio
			image
			name
		}
		allMdx(limit: 3, filter: { frontmatter: { category: { eq: $category }, slug: { ne: $slug } } }) {
			nodes {
				frontmatter {
					slug
					articleCardTitle
					articleCardDescription
					category
				}
			}
		}
	}
`;
